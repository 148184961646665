import { ulid } from 'ulid';

import {
  IQuickbooksInvoiceFieldMappingConfigKey,
  MappingConfigType,
} from '@site-mate/sitemate-flowsite-shared';

const generateLineItemMappingSetArray = (
  dashpivotId: string | undefined,
  quickbooksId: string | undefined
) => [
  {
    _id: ulid(),
    mappings: [
      {
        _id: ulid(),
        key: IQuickbooksInvoiceFieldMappingConfigKey.ItemName,
        isMultiple: false,
        isRequired: true,
        type: MappingConfigType.Single,
        source: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: dashpivotId,
              fieldType: 'inputData',
              customResolver: 'dashpivotTableColumnField',
              filter: "form.items[kind in ['table', 'prefilledTable']].columns",
            },
          ],
          valueData: undefined,
        },
        destination: {
          isEditable: false,
          fields: [],
          valueData: {
            integrationId: quickbooksId,
            label: 'Product/Service',
          },
        },
        metadata: {},
      },
      {
        _id: ulid(),
        key: IQuickbooksInvoiceFieldMappingConfigKey.Quantity,
        isMultiple: false,
        isRequired: true,
        type: MappingConfigType.Single,
        source: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: dashpivotId,
              fieldType: 'inputData',
              customResolver: 'dashpivotTableColumnField',
              filter: "form.items[kind in ['table', 'prefilledTable']].columns",
            },
          ],
          valueData: undefined,
        },
        destination: {
          isEditable: false,
          fields: [],
          valueData: {
            integrationId: quickbooksId,
            label: 'Qty',
          },
        },
        metadata: {},
      },
      {
        _id: ulid(),
        key: IQuickbooksInvoiceFieldMappingConfigKey.Rate,
        isMultiple: false,
        isRequired: false,
        type: MappingConfigType.Single,
        source: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: dashpivotId,
              fieldType: 'inputData',
              customResolver: 'dashpivotTableColumnField',
              filter: "form.items[kind in ['table', 'prefilledTable']].columns",
            },
          ],
          valueData: undefined,
        },
        destination: {
          isEditable: false,
          fields: [],
          valueData: {
            integrationId: quickbooksId,
            label: 'Rate',
          },
        },
        metadata: {},
      },
      {
        _id: ulid(),
        key: IQuickbooksInvoiceFieldMappingConfigKey.Description,
        isMultiple: false,
        isRequired: false,
        type: MappingConfigType.Single,
        source: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: dashpivotId,
              fieldType: 'inputData',
              customResolver: 'dashpivotTableColumnField',
              filter: "form.items[kind in ['table', 'prefilledTable']].columns",
            },
          ],
          valueData: undefined,
        },
        destination: {
          isEditable: false,
          fields: [],
          valueData: {
            integrationId: quickbooksId,
            label: 'Description',
          },
        },
        metadata: {},
      },
    ],
  },
];

export const generateQuickbooksInvoiceMappings = (
  dashpivotId: string | undefined,
  quickbooksId: string | undefined
) => [
  {
    _id: ulid(),
    key: IQuickbooksInvoiceFieldMappingConfigKey.Customer,
    isMultiple: false,
    isRequired: true,
    type: MappingConfigType.Single,
    source: {
      isEditable: true,
      fields: [
        {
          _id: ulid(),
          integrationId: dashpivotId,
          fieldType: 'inputData',
          customResolver: 'dashpivotField',
          filter: "form.items[kind='category']",
        },
      ],
      valueData: undefined,
    },
    destination: {
      isEditable: false,
      fields: [],
      valueData: {
        integrationId: quickbooksId,
        label: 'Customer',
      },
    },
    metadata: {},
  },
  {
    _id: ulid(),
    key: IQuickbooksInvoiceFieldMappingConfigKey.InvoiceDate,
    isMultiple: false,
    isRequired: false,
    type: MappingConfigType.Single,
    source: {
      isEditable: true,
      fields: [
        {
          _id: ulid(),
          integrationId: dashpivotId,
          fieldType: 'inputData',
          customResolver: 'dashpivotField',
          filter: "form.items[kind in ['date', 'datePlain']]",
        },
      ],
      valueData: undefined,
    },
    destination: {
      isEditable: false,
      fields: [],
      valueData: {
        integrationId: quickbooksId,
        label: 'Date',
      },
    },
    metadata: {},
  },
  {
    _id: ulid(),
    key: IQuickbooksInvoiceFieldMappingConfigKey.LineItems,
    isMultiple: true,
    isRequired: false,
    type: MappingConfigType.MultipleSet,
    items: generateLineItemMappingSetArray(dashpivotId, quickbooksId),
  },
];
